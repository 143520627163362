@import "src/variables";

.alert {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  background: $color-bg-white;
  max-width: 800px;
  border: 1px solid $color-silver;
  border-radius: 8px;
  gap: 13px;

  .alert__text-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .alert__title,
  .alert__description {
    color: #18181B;
  }

  .alert__title {
    font: $font-500-16;
    line-height: 16px;
  }

  .alert__description {
    font: $font-400-14;
    line-height: 20px;
  }
}