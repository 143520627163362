@import 'src/variables';

.dropdown {
    min-width: 240px;
    left: 0;
    bottom: 0;
    padding: 12px 0 24px;
    display: flex;
    flex-direction: column;
    position: absolute;
    transform: translateY(100%);
    transition: $transition-duration;
    background-color: $color-white;
    border-radius: 8px;
    overflow: hidden;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1);


    .option {
        padding: 14px 24px;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: left;
        font: $font-500-16;
        cursor: pointer;

        &:hover {
            background-color: #f4f4f5;
        }

        &:last-of-type {
            border-bottom: 1px #e4e4e7 solid;
        }

    }

    .option-icon {
        width: 24px;
        height: 24px;

        img {
            width: auto;
            height: 100%;
            max-height: 100%;
        }
    }
}

.dropdown--active {
    bottom: -6px;
    visibility: visible;
    opacity: 1;
}