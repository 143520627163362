@import "src/variables";

.expand-block-container {
  display: flex;
  flex-direction: column;
  transition: gap $transition-duration ease;

  .expand-block-title-wrapper {
    display: flex;
    padding: 8px 16px;
    cursor: pointer;
  }

  .expand-block__title {
    flex-grow: 1;
    color: $color-text-gray;
    font: $font-400-12;
    line-height: 20px;
  }

  .expand-block__icon {
    display: flex;
    align-items: center;
  }

  .expand-block__content {
    overflow: hidden;
    transition: max-height $transition-duration ease;
  }
}