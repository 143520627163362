@import 'src/variables';

.burger {
    width: 32px;
    height: 32px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .line {
        width: 22px;
        height: 1px;
        background-color: $color-black;
        transition: $transition-duration;
        position: absolute;
        transform-origin: center;

        &:nth-child(1) {
            transform: translateY(-5px);
        }

        &:nth-child(3) {
            transform: translateY(5px);
        }
    }
}

.burger--active {
    .line:nth-child(1) {
        transform: rotate(45deg);
    }

    .line:nth-child(3) {

        transform: rotate(-45deg);
    }

    .line:nth-child(2) {
        opacity: 0;
        visibility: hidden;
    }
}