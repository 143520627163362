@import "variables.scss";


.marker {
  width: 100%;
  height: 100%;

  .pin {
    width: 2px;
    height: 18px;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      width: 4px;
      height: 4px;
      bottom: 0;
      background-color: inherit;
      transform: translate(-50%);
      left: 50%;
      border-radius: 4px;
    }
  }

  .marker__text {
    padding: 1px 6px;
    width: auto;
    background-color: $color-white;
    border-style: solid;
    text-align: center;
    font: $font-500-13;
    line-height: 16px;
    position: absolute;
  }

  .marker__mine-name {
    top: -300%;
    width: max-content;
    color: $color-white;
  }

  .marker__probability {
    top: -200%;
    position: absolute;
    color: $color-white;
  }

  .marker__probabilities {
    top: -100%;
    position: absolute;
    display: flex;
    padding: 0 0 0 2px;

    &>* {
      padding: 0 5px;
    }

    .probability_rgb {
      background-color: #FF0000;
    }

    .probability_multispectral {
      background-color: #00CB50;
    }

    .probability_thermal {
      background-color: #00D1FF;
    }
  }
}