@import "src/variables";

.icon-button {
  width: 32px;
  height: 32px;
  padding: 8px;
  position: relative;
  background-color: $color-white;
  border-radius: 4px;
  border: 1px $color-silver solid;
  background-color: $color-white;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;

  &:hover {
    background: #f4f4f5;

  }

  img {
    width: 100%;
    height: auto;
  }
}

.icon-button--disabled {
  background: #f4f4f5;
  opacity: 0.5;
  cursor: default;
}