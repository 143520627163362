@import 'src/variables';

.status {
    display: flex;
    gap: 10px;
    align-items: center;

    .status__icon {
        width: 16px;
        height: 16px;

        img {
            width: auto;
            height: 100%;
        }
    }

    .status__icon--rotating {
        animation: rotate 2s linear infinite;
    }

    .status__label {
        font: $font-500-12;
        color: #71717A;
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}