@import "src/variables";

.secondary {
  padding: 8px 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  color: $color-black-bg-primary;
  background: $color-white;
  border: 1px solid $color-silver;
  border-radius: 4px;
  transition: $transition-duration;
  font: $font-500-12;
  cursor: pointer;
  width: max-content;

  .secondary__icon {
    width: 16px;
    height: 16px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &:hover {
    opacity: 0.6;
  }
}