@import "variables.scss";

.details {
  height: calc(100% - 19px - var(--response-padding));
  max-width: calc(563px + var(--response-padding) * 2);
  width: 100%;
  padding-left: var(--response-padding);
  position: absolute;
  top: 19px;
  z-index: 402;
  pointer-events: none;
  border-radius: 8px;
  transition: all $transition-duration ease;

  .details-wrapper {
    height: 100%;
    width: 100%;
    left: -100%;
    position: absolute;
    pointer-events: all;
    transition: all $transition-duration ease;
  }

  .details__close {
    top: 2px;
    right: 2px;
    position: absolute;
    cursor: pointer;
    //for covering modal
    z-index: 16;
  }

  .details-content {
    max-height: 100%;
    margin: 0 var(--response-padding);
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 8px;
    background-color: $color-white;
    overflow: auto;
  }

  .details__content-flex-container {
    padding: 20px 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .details__head {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .head__marker {
      width: 38px;
      height: 52px;
      position: relative;

      &:hover {
        div::after {
          display: none;
        }

        div::before {
          display: none;
        }

        span::after {
          display: none;
        }

        span::before {
          display: none;
        }
      }

      &>div>span {
        left: 11px;
      }
    }

    .head__content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      color: $color-black;

      .head__title {
        font: $font-500-20;
        color: inherit;
      }

      .head__tags {
        padding: 2px 4px;
        font: $font-400-13;
        background-color: #ececec;
        color: inherit;
        width: max-content;
      }

      .head__coordinate {
        font: $font-400-11;
        color: inherit;
      }
    }
  }

  .details__layers-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }

  .layer-button {
    min-width: 84px;
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
  }

  .layer-button__info {
    font: $font-500-12;
    line-height: 17px;
    color: $color-text;
    padding: 0px 4px;
  }

  .layer-button__underline {
    height: 2px;
    width: 100%;
    background-color: $color-black-bg-primary;
    opacity: 0;
  }

  .layer-button__active-underline {
    @extend .layer-button__underline;
    opacity: 1;
  }

  .details__description {
    display: flex;
    flex-direction: column;
    gap: 16px;

    font: $font-400-12;
    color: $color-text;
    line-height: 14.5px;

    h3 {
      font: $font-500-16;
      line-height: 19px;
      color: $color-text;
    }

    li {
      display: grid;
      grid-template-columns: minmax(85px, 85px) minmax(min-content, 1fr);
      gap: 10px;
    }
  }

  .details__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .details__other-data {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .other-data__title {
      color: #18181b;
      font: $font-600-16;
    }

    .other-data__tags {
      display: flex;
      gap: 10px;

      span {
        padding: 2px 4px;
        background-color: #f4f4ef;
        font: $font-400-14;
        color: $color-black;
        line-height: 20px;
      }
    }
  }

  .sensor-fusing__container {
    display: flex;
    flex-direction: column;
  }

  .sensor-fusing__name {
    background-color: $color-black;
    color: #fafafa;
    width: max-content;
    padding: 0 4px;
    font: $font-500-12;
    line-height: 17px;
  }
}

.details--active {
  .details-wrapper {
    left: 0;
  }
}