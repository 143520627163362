@import "src/variables";



.main {
  @include container;
  margin: 108px auto;
}

.main-full-fill {
  @extend .main;
  max-width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.settings__wrapper {
  display: flex;
  position: absolute;
  top: 20px;
  right: var(--response-padding);
  gap: 30px;
  align-items: center;

  .user {
    font: $font-500-18;
  }
}