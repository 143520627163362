@import "variables.scss";

.map-confirm-controls {
  position: absolute;
  bottom: calc(var(--response-padding) + 62px);
  left: 50%;
  transform: translate(-50%);
  z-index: 402;
  background-color: $color-bg-white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px;
  cursor: default;

  .icon-button {
    width: 36px;
    height: 36px;
  }
}
