@import "src/variables";

.secondary {
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: $color-text;
  background: $color-white;
  border: 1px solid $color-silver;
  border-radius: 4px;
  transition: $transition-duration;
  line-height: 20px;
  font: $font-500-12;
  cursor: pointer;
  width: max-content;

  &:hover {
    box-shadow: inset 0px 0px 0px 2px $color-white;
  }

  .secondary__icon {
    width: 16px;
    height: 16px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.secondary--disabled {
  opacity: 0.5;
  cursor: default;
}