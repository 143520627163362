@import 'src/variables';

.main-util__wrapper {
    width: 100%;
    height: 173.334px;
    padding: 28px 60px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    background-color: $color-white;
    border-radius: 8px;

    .main-util__icon {
        width: 120px;
        height: 90px;
        transition: $transition-duration;

        img {
            max-width: 100%;
            max-height: 100%;
            height: 100%;
            margin: 0 auto;
        }
    }

    .main-util__title {
        font: $font-500-16;
        color: $color-text;
    }

    &:hover {
        .main-util__icon {
            transform: scale(1.1);
        }
    }
}

@media (max-width: $media-max-width-md) {
    .main-util__wrapper {
        padding: 20px;
    }
}