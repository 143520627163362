.preloader-container {
  position: relative;
  width: 100%;
  min-height: 100%;
  overflow: auto;

  .preloader-container__title {
    position: absolute;
    z-index: 15;
  }

  &.preloader-container-loading {
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 15;
      // background: rgba(233, 239, 245, 0.7);
    }

    &.opacity {
      &:after {
        background-color: transparent;
      }
    }

    .preloader-wrap {
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
      padding: 0;
      align-items: center;
      justify-content: center;
      z-index: 16;

      img {
        margin: unset;
      }
    }
  }
}