@import "variables.scss";

.marker {
  width: 16px;
  height: 16px;
  background-color: $color-bg-white;
  border: 1px solid #00D1FF;
  border-radius: 50%;
  cursor: url("../../../../assets/icons/remove-pen-tool.svg"), auto;
}
