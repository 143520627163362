@import "src/variables";

.modal-container {
  max-width: 548px;
}

.preloader-modal {
  display: flex;
  flex-direction: column;

  .preloader-modal__content {
    margin-bottom: 36px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .preloader-modal__title {
    font-size: 18px;
    color: #09090B;
  }

  .preloader-modal__description {
    font-size: 14px;
    color: #71717A;
  }
}