.modal__form {
    $form-coordinates-gap: 10px;

    display: flex;
    flex-direction: column;
    gap: 16px;

    .form__coordinates {
        display: flex;
        gap: $form-coordinates-gap;
    }

    .form__diameter {
        width: calc(50% - $form-coordinates-gap);
        display: flex;
        gap: 16px;
        align-items: end;

        .diameter__dimensions {
            font-size: 14px;
            line-height: 35px;
        }

        .silver-color {
            color: #71717A;
        }

    }
}