@import "src/variables";

.modal-container {
  max-width: 980px;
}

.modal {
  display: flex;
  flex-direction: column;
  row-gap: 36px;

  .modal__text-container {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .modal__title {
    font: $font-600-18;
    line-height: 18px;
  }


  .modal__description {
    font: $font-400-14;
    color: $color-text-gray-light
  }

  .modal__controls-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 16px;
  }

  .modal__providers-table {
    margin-top: 36px;

    .providers-table__row {
      display: grid;
      grid-template-columns: minmax(372px, 372px) minmax(200px, 200px) minmax(180px, 180px) minmax(180px, 180px);
      gap: 10px;
    }

    .providers-table__head {
      @extend .providers-table__row;
      padding-left: 20px;

      * {
        color: #71717A;
        font: $font-500-12;
        line-height: 20px;
      }
    }

    .providers-table__body {
      @extend .providers-table__row;

      margin-top: 5px;
      padding: 5px 0 5px 20px;
      position: relative;
      background-color: $color-white;

      .drag-dots {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0px, -50%);
      }

      // * {
      //   color: #71717A;
      //   font: $font-500-12;
      //   line-height: 20px;
      // }
    }

    .name {
      color: #71717A;
      font: $font-500-12;
      display: flex;
      align-items: center;
    }

  }

  .modal__upload {
    margin-top: 10px;
    padding-left: 20px;
    display: flex;
    gap: 10px;

    .label {
      width: 100%;
      max-width: 372px;
      color: #71717A;
      font: $font-500-12;
    }
  }
}