@import "variables.scss";

.marker {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  position: relative;

  .plane {
    width: 100%;
    height: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color-white;

    img {
      width: auto;
      height: 14px;
    }
  }


  &:before,
  &:after {
    content: '';
    display: block;
    width: 1px;
  }

  &:before {
    height: 8px;
    background-color: $color-white;
    order: 1;
  }

  &:after {
    height: 5px;
    background-color: #FF0000;
    order: 2;
  }
}