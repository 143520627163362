@import "src/variables";

.orthophoto-page {
  display: flex;
  gap: 440px;

  &__buttons-container {
    display: flex;
    gap: 40px;
    margin-top: 40px;
  }

  &__options-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
    max-width: 300px;
  }

  .tasks-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .tasks-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border: 1px solid $color-black-bg-primary;
    border-radius: 6px;
    background-color: $color-bg-white;
  }

  .task-item {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
}

@media (max-width: $media-max-width-lg) {
  .orthophoto-page {
  }
}

@media (max-width: $media-max-width-sm) {
  .orthophoto-page {
    &__buttons-container {
      flex-direction: column;
      gap: 30px;
      & > button {
        width: max-content;
      }
    }
  }
}
