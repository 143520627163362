@import 'src/variables.scss';

.team-and-staff-page {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .team-and-staff-page__head {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    .team-and-staff-page__title {
        font: $font-500-18;
        color: #18181B;
        line-height: 28px;
    }

    .team-and-staff-page__description {
        font: $font-400-14;
        color: #71717A;
        line-height: 20px;
    }

    .team-and-staff-page__top {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .search-form {
            width: 100%;
            max-width: 304px;
            margin-right: auto;
        }

        .team-and-staff-page__actions {
            display: flex;
            gap: 20px;
        }
    }

    .team-and-staff-page__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .deputies {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .deputies__tite {
            font: $font-500-14;
            line-height: 20px;
        }

        .deputies__list {
            overflow: auto;
            max-height: 220px;
            border-radius: 8px;
            background-color: #F4F4F5;
        }

        .deputy {
            display: grid;
            grid-template-columns: 58% 4fr 1fr;
            padding: 10px 24px;
            font: $font-400-12;
            color: #18181B;

            &:not(:last-of-type) {
                border-bottom: 1px #E4E4E7 solid;
            }

            .deputy__user,
            .deputy__role {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-right: 5px;
            }
        }
    }
}

@media (max-width: $media-max-width-lg) {
    .team-and-staff-page {
        .team-and-staff-page__top {
            flex-direction: column;
            gap: 10px;

            .search-form {
                max-width: unset;
            }

            .team-and-staff-page__actions {
                justify-content: flex-end;
                gap: 10px;
            }
        }
    }
}