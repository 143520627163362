@import "src/variables";

.form-field-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
  }

  label[aria-disabled="true"] {
    color: #71717A;
  }
}

.form-field__input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid $color-silver;
  border-radius: 6px;
  font-size: 16px;
  outline: none;
  font: $font-400-14;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;

  &:hover {
    border-color: $color-bg-primary;
  }

  &:disabled {
    background-color: $color-white;
    border: 1px solid $color-silver;
    color: #71717A;
  }


  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.form-field__input--with-icon {
  padding-left: 36px;
}

.form-field__icon {
  height: 100%;
  width: 36px;
  position: absolute;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
  }
}

.form-field__icon--disabled {
  opacity: 0.5;
}


.error {
  position: absolute;
  bottom: 0;
  transform: translate(2px, calc(100% + 2px));
  font: $font-400-12;
  color: #FF0000;

}