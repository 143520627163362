@import "src/variables";

.form-field-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;

  label {
    font-size: 14px;
    line-height: 28px;
  }

  label[aria-disabled="true"] {
    color: #71717A;
  }

  .error {
    position: absolute;
    bottom: 0;
    transform: translate(2px, calc(100% + 2px));
    font: $font-400-12;
    color: #FF0000;
  }
}