@import "src/variables";

.map-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  border-top: 1px #818181 solid;

  .map {
    flex-grow: 1;
    height: 100%;
    width: 100%;
  }

  :global {
    .leaflet-container {
      cursor: default;
    }

    .leaflet-container:active {
      cursor: grabbing;
    }

    .grabbing {
      cursor: grabbing;
    }

    .leaflet-container .leaflet-interactive {
      cursor: pointer;
    }
  }
}

.map-page__draw-mode {
  @extend .map-page;

  :global {
    .leaflet-container {
      cursor: url("../../../assets/icons/draw-poligon.svg"), auto;
    }
  }
}

.creation-area-modal-container {
  max-width: 398px;

  .delete-unit-button {
    position: absolute;
    bottom: 24px;
  }
}

.project-title {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%);
  z-index: 402;
  background-color: $color-bg-white;
  border-radius: 8px;
  font: $font-400-12;
  line-height: 20px;
}

.map-page__hided-input {
  display: none;
}

@media (max-width: $media-max-width-lg) {
  .creation-area-modal-container {
    .creation-area-modal-body {
      padding: 24px;
    }

    .creation-area-modal-body-close-btn {
      padding: 8px;
    }
  }
}

@media (max-width: $media-max-width-sm) {
  .map-page {
  }
}

.context-menu {
  position: absolute;
  z-index: 99999999999;
}
