@import "src/variables";

.modal-container {
  max-width: 548px;
}

.progress-modal {
  display: flex;
  flex-direction: column;
  row-gap: 36px;

  .progress-modal__text-container {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .progress-modal__title {
    font: $font-500-18;
    line-height: 18px;
  }

  .progress-modal__description {
    font: $font-400-14;
    color: $color-text-gray-light
  }

  .progress-modal__accept-button {
    width: max-content;
    margin-left: auto;
  }
}