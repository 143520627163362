@import 'src/variables';

.upload__input {
    display: none;
}

.drop-area {
    width: 100%;
    height: 72px;
    padding: 8px 30px;
    text-align: center;
    color: #464649;
    font: $font-400-14;
    background-color: $color-white;
    border-radius: 6px;
    border: 1px #464649 dashed;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    &:before {
        content: "";
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        left: 50%;
        top: 50%;
        transition: $transition-duration;
        border-radius: 6px;
        border: 1px #464649 solid;
        transform: translate(-50%, -50%);
        opacity: 0;
        background-color: $color-white;
        pointer-events: none;
    }

    span {
        font: $font-400-10;
        color: #464649;
    }

    .drop-area__upload {
        width: 40px;
        height: 40px;
        top: 50%;
        left: 50%;
        position: absolute;
        z-index: 2;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: $transition-duration;
        pointer-events: none;

        img {
            width: 100%;
            height: auto;
        }
    }
}

.drop-area--over {
    background-color: transparent;

    &:before {
        height: 150%;
        width: 110%;
        opacity: 1;
    }

    .drop-area__upload {
        opacity: 1;

    }
}

.drop-area--selected {
    background-color: #E4E4E7;


}