@import "src/variables";

.modal-container {
  max-width: 548px;
}

.form-modal {
  display: flex;
  flex-direction: column;
  row-gap: 36px;

  .form-modal__text-container {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .form-modal__title {
    font: $font-600-18;
    line-height: 18px;
  }

  .form-modal__description {
    font: $font-400-14;
    color: $color-text-gray-light
  }

  .form-modal__description-warning {
    color: $warning-red;
  }

  .form-modal__controls-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 16px;
  }

  .form {
    display: flex;
    flex-direction: column;
    row-gap: 36px;
  }

  .form__values-wrapper {
    max-width: 350px;
  }
}
