@import "src/variables";

.progress-bar-wrapper {
  width: 100%;
  background-color: $color-bg-gray;
  border-radius: 100px;
  overflow: hidden;

  .progress-bar {
    height: 16px;
    background-color: $color-black-bg-primary;
    border-radius: 100px;
    transition: $transition-duration;
  }
}