@import "src/variables";

.report-actions {
  width: 50%;
  display: flex;
  gap: 20px;
  position: absolute;
  top: var(--response-padding);
  right: var(--response-padding);
}

.report-actions-spacer {
  margin-left: auto;
}


@media (max-width: $media-max-width-lg) {
  .map-page {}
}

@media (max-width: $media-max-width-sm) {
  .map-page {}
}