@import "src/variables";

.employee-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .employee-form__title {
        margin-bottom: 8px;
        font: $font-500-16;
        color: #18181B;
        line-height: 20px;
    }
}

.password {
    padding: 20px;
    background-color: #ECECEC;
    color: #18181B;
    font: $font-400-16;
    line-height: 18px;
    gap: 10px;
    border-radius: 8px;
    display: flex;
    cursor: pointer;

    p {
        word-break: break-all;
    }

    .copy {
        height: 16px;
        width: 16px;
    }

    &:hover {
        background-color: #C8C8C9;
    }
}

.password__copied {
    @extend .password;
    justify-content: center;
    background-color: #C8C8C9;
    cursor: default;
}

.warning {
    line-height: 20px;
    font: $font-400-14;
    color: #FF0000;

    img {
        display: inline;
        vertical-align: top;
    }
}