@import "src/variables";

.show-hide-container {
  padding: 10px 0;
  display: flex;
  gap: 12px;
  border-radius: 4px;
  align-items: center;
  cursor: pointer;

  .show-hide__icon {
    width: 16px;
    height: 16px;
  }

  .show-hide__title {
    flex-grow: 1;
    color: #18181b;
    font: $font-500-14;
    line-height: 20px;
  }
}

.show-hide-container__disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}