@import "src/variables";

.loader {
    width: 16px;
    height: 16px;
    aspect-ratio: 1;
    border-radius: 50%;
    display: inline-block;
    margin-right: 6px;
    background:
        radial-gradient(farthest-side, $color-black 94%, #0000) top/8px 8px no-repeat,
        conic-gradient(#0000 30%, $color-black);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 1px), $color-black 0);
    animation: l13 1s infinite linear;
}

.loader--light {
    @extend .loader;
    background:
        radial-gradient(farthest-side, $color-white 94%, #0000) top/8px 8px no-repeat,
        conic-gradient(#0000 30%, $color-white);
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 1px), $color-white 0);
}

@keyframes l13 {
    100% {
        transform: rotate(1turn)
    }
}