@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

$color-bg-white: #ffffff;
$color-bg-primary: #d6d6d6;
$color-bg-gray: #f4f4f5;
$color-black-bg-primary: #18181b;
$color-white: #ffffff;
$color-black: #000000;
$color-silver: #e4e4e7;
$color-text: #09090b;
$color-text-gray: #818181;
$color-text-gray-light: #71717a;
$warning-red: #ff0000;

$transition-duration: 0.4s;

$font-400-10: normal 400 10px Inter,
sans-serif;

$font-400-11: normal 400 12px Inter,
sans-serif;

$font-400-12: normal 400 12px Inter,
sans-serif;

$font-500-12: normal 500 12px Inter,
sans-serif;

$font-500-13: normal 500 13px Inter,
sans-serif;
$font-400-13: normal 400 13px Inter,
sans-serif;

$font-500-14: normal 500 14px Inter,
sans-serif;
$font-400-14: normal 400 14px Inter,
sans-serif;

$font-400-16: normal 400 16px Inter,
sans-serif;
$font-500-16: normal 500 16px Inter,
sans-serif;
$font-500-18: normal 500 18px Inter,
sans-serif;
$font-600-16: normal 600 16px Inter,
sans-serif;
$font-600-18: normal 600 18px Inter,
sans-serif;
$font-700-16: normal 700 16px Inter,
sans-serif;

$font-400-18: normal 400 18px Inter,
sans-serif;

$font-400-20: normal 400 20px Inter,
sans-serif;
$font-500-20: normal 500 20px Inter,
sans-serif;
$font-600-24: normal 600 24px Inter,
sans-serif;
$font-700-24: normal 700 24px Inter,
sans-serif;

$max-header-height: 48px;

$media-max-width-xs: 375px;
$media-max-width-sm: 500px;
$media-max-width-transitional-md: 665px;
$media-max-width-md: 768px;
$media-max-width-lg: 992px;
$media-max-width-transitional-xl: 1160px;
$media-max-width-xl: 1300px;

$table-header-z-index: 1;
$navigation-z-index: 6;
$header-z-index: 10;
$modals-z-index: 450;
$tooltips-z-index: 1000;


@mixin container {
  width: 100%;
  margin: 0 auto;
  padding: 0 var(--response-padding);
}