@import "src/variables";

.settings-page {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    padding: 108px var(--response-padding) var(--response-padding);
    gap: 48px;


    .side-bar {
        max-width: 250px;
        width: 100%;
        gap: 24px;
        display: flex;
        flex-direction: column;

        .side-bar__title {
            font: $font-700-24;
            color: #18181B;
            line-height: 40px;
        }

        .side-bar__options {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .option {
                flex: 1;
                padding: 8px 16px;
                color: #18181B;
                border-radius: 4px;
                display: flex;
                gap: 8px;
                align-items: center;
            }

            .option__title {
                font: $font-500-14;
                line-height: 20px;
            }

            .option__icon {
                width: 16px;
                height: 16px;

                img {
                    width: auto;
                    height: 100%;
                }
            }

            .option--active {
                background-color: #F4F4F5;
                color: #464649;
            }
        }
    }

    .divider {
        width: 1px;
        background-color: #C8C8C9;
    }

    .settings {
        width: 100%;
    }


}

@media (max-width: $media-max-width-lg) {
    .settings-page {
        gap: 24px;

        .side-bar {
            max-width: 200px;
        }
    }
}

@media (max-width: $media-max-width-md) {
    .settings-page {
        gap: 12px;

        .side-bar {
            max-width: 60px;


            .side-bar__title {
                display: none;
            }

            .side-bar__options {
                .option {
                    justify-content: center;

                    .option__title {
                        display: none;
                    }

                    .option__icon {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
    }
}