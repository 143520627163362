.slider__image {
    width: 100%;
}

.slider__pagination {
    margin-top: 15px;
    justify-content: start;
    gap: 4px;
}

.slider__page {
    height: 9px;
    width: 9px;
    border-radius: 9px;
    background-color: #D9D9D9;
    border: none;
}

.slider__page[aria-selected=true] {
    background-color: #818181;

}