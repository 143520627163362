@import "src/variables";

.wrapper {
    display: flex;
    gap: 14px;
    justify-content: center;
    align-items: center;

    .wrapper__label {
        color: #71717A;
        font: $font-500-14;
    }

    .wrapper__selected {
        width: 142px;
        padding: 10.5px 12px;
        display: flex;
        gap: 10px;
        position: relative;
        border: 1px $color-silver solid;
        border-radius: 4px;
        font: $font-400-14;
        color: $color-text;
        cursor: pointer;
        justify-content: space-between;


        .selected__chevron {
            width: 16px;
            height: 16px;
            transition: $transition-duration;

            img {
                width: 100%;
                height: auto;
            }

        }
    }

    .wrapper__selected--active {
        .selected__chevron {
            transform: rotate(180deg);
        }
    }

    .listener {
        width: 100%;
        height: 100%;
        position: absolute;
    }
}