@import './variables.scss';

.wrapper {
    overflow: hidden;
}

.wrapper__body {
    width: 100%;
    max-height: 0;
    background-color: $color-white;
    border-radius: 8px;
    cursor: auto;
    height: auto;
    overflow: hidden;
    transition: max-height $transition-duration ease;
}

.wrapper__body--scroll {
    overflow: auto;
}

.wrapper__content {
    transition: all $transition-duration ease;
    overflow: hidden;
}