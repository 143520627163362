@import "variables.scss";

.white-text {
  color: $color-white;
}

.marker {
  width: 100%;
  height: 100%;

  .pin {
    width: 2px;
    height: 18px;
  }

  .marker__text {
    width: auto;
    padding: 0 5px;
    background-color: $color-white;
    text-align: center;
    font: $font-500-13;
    line-height: 17px;
    position: absolute;
  }

  .marker__mine-name {
    top: -200%;
    width: max-content;
  }

  .marker__probability {
    top: -100%;
    position: absolute;
    display: flex;
  }

}