@import "variables.scss";

.modal__form {
  $form-coordinates-gap: 10px;

  display: flex;
  flex-direction: column;
  gap: 36px;

  .main-area-data-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .area-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .area-label {
    font: $font-500-14;
    line-height: 20px;
  }

  .area__count-container {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .name-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .area-count {
    font: $font-500-12;
    line-height: 20px;
  }

  .color-configuration-block {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.color-configurator {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .color-select-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
    column-gap: 16px;
  }

  .colors-wrapper {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .opacity-field {
    max-width: 93px;
  }

  .percentages-mark {
    font: $font-400-14;
    line-height: 20px;
  }

  .colors-list {
    display: flex;
    align-items: center;
    gap: 13px;
  }

  .selected-button {
    outline: 1px solid #a1a1aa;
  }

  .color-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
  }

  .opacity-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
