@import "variables.scss";

.area-list-item {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;

  .area-name {
    font: $font-400-12;
    line-height: 20px;
  }

  .external-area-controls-container {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .area-controls {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .icon-button {
    border: 0;
    box-shadow: none;
  }

  .color-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 0;
    box-shadow: none;
    pointer-events: none;
  }
}
