@import "src/variables";

.header {
  @include container;
  position: relative;
  display: flex;

  .header__wrapper {
    position: fixed;
    top: 20px;
    z-index: 402;
    background-color: $color-white;
    width: max-content;
    border-radius: 8px;
    padding: 0 20px;
    height: $max-header-height;
    display: flex;
    gap: 30px;
    align-items: center;
  }

  .header__wrapper--scrolled {
    box-shadow: 0px 1px 5px 0px #0000001a;
  }

  .header__ful-fill-wrapper {
    @extend .header__wrapper;
    left: var(--response-padding);
  }


  .header__divider {
    width: 1px;
    height: 100%;
    margin: 0 20px;
    background-color: $color-silver;
  }

  .header__logo {
    max-width: 100px;

    img {
      width: 100%;
    }
  }

  .header__title {
    font: $font-700-16;
    text-transform: uppercase;
  }
}