@import "src/variables";

.wrapper {
  width: 100%;

  .wrapper__head {
    min-height: 40px;
    margin-bottom: 40px;
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
  }

  .wrapper__body {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .wrapper__teams {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 24px;
    overflow-x: auto;
  }
}

@media (max-width: $media-max-width-lg) {
  .wrapper {
    width: 100%;

    .wrapper__head {
      .title {
        width: 100%;
      }
    }
  }
}