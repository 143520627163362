@import "variables.scss";

.buttons {
  position: absolute;
  left: var(--response-padding);
  bottom: var(--response-padding);
  z-index: 401;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;

  .icon-button {
    width: 36px;
    height: 36px;
  }
}