@import "src/variables";

.auth-page {
    flex-grow: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;

    .loading {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .left,
    .right {
        padding: 40px var(--response-padding);
        ;
        flex-grow: 1;
        width: 50%;
        display: flex;
    }

    .left {
        flex-direction: column;
        background-color: $color-black-bg-primary;

        .logo {
            width: 100px;

            img {
                width: 100%;
            }
        }

        .description,
        .signature {
            color: $color-white;
        }

        .description {
            margin-top: 14px;
            font: $font-400-18;
            line-height: 28px;
        }

        .signature {
            margin: 8px 0;
            font: $font-400-14;

        }

        .demonstration-img {
            margin-top: auto;

            img {
                width: 100%;
            }
        }
    }

    .right {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #ECECEC;

        .form {
            width: 350px;
            margin-top: 42px;
            display: flex;
            flex-direction: column;
            gap: 24px;

            *> {
                width: 100%;
            }
        }

        .title {
            padding-top: 8px;
            font: $font-600-24;
            color: $color-text;
            letter-spacing: -0.6px;
            line-height: 32px;
        }

        .info {
            font: $font-400-14;
            color: #71717A;
            line-height: 20px;
        }

        .checkbox {
            flex-direction: row;
            align-items: center;

            label {
                color: $color-text;
                font: $font-500-14;
            }
        }
    }
}


@media (max-width: $media-max-width-md) {
    .auth-page {
        flex-direction: column;

        .right {
            order: -1;

            .form {
                width: 100%;
            }
        }

        .left,
        .right {

            width: 100%;
        }

    }

}