@import "src/variables";

.project {
  position: relative;
  display: grid;
  align-items: center;
  height: 52px;
  gap: 20px;
  padding: 0 19px 0 24px;
  border-top: 1px solid $color-silver;
  grid-template-columns: minmax(300px, 10fr) minmax(150px, 2fr) minmax(
      80px,
      80px
    );
  cursor: pointer;

  &:hover {
    background-color: $color-bg-gray;
  }

  .project__title {
    margin-right: auto;
    font: $font-400-12;
    color: $color-black;
    word-break: break-word;
  }

  .project__date {
    font: $font-400-12;
    color: #71717a;
  }

  .project__actions-container {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .project__actions-button {
    border-radius: 6px;
  }
}

@media (max-width: $media-max-width-transitional-md) {
  .project {
    border-top: none;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $color-silver;
      width: 630px;
    }
  }
}
