@import "src/variables";

.layers {
  height: calc(100% - 40px);
  width: 300px;
  position: absolute;
  right: var(--response-padding);
  top: 20px;
  z-index: 402;
  overflow: hidden;
  pointer-events: none;

  .layers__button {
    max-width: 300px;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: $color-white;
    cursor: pointer;
    pointer-events: all;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    transition: $transition-duration;
    max-height: 48px;
    overflow: hidden;
  }

  .layers__button-label {
    font: $font-400-14;
    line-height: 20px;
    color: $color-text-gray;
  }

  .layer__button-icon {
    width: 16px;
    height: 16px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      position: absolute;
      transition: $transition-duration;
    }

    .icon {
      opacity: 1;
    }

    .icon--active {
      opacity: 0;
    }
  }

  .layers__button-small-container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 8px 16px;
    border-bottom: 1px solid transparent;
    transition: border $transition-duration;
  }

  .layers-wrapper {
    width: 100%;
    max-height: 0;
    background-color: $color-white;
    transition: $transition-duration;
    border-radius: 8px;
    padding: 0;
    cursor: auto;
    height: auto;
    overflow: hidden;
  }

  .layers-content {
    display: flex;
    flex-direction: column;

    & > *:not(:last-child) {
      border-bottom: 1px solid $color-silver;
    }
  }

  .layers-container {
    padding: 8px 16px;
  }

  .areases-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }

  .see-all-button {
    display: flex;
    align-items: center;
    gap: 4px;
    font: $font-400-12;
    line-height: 20px;
    color: $color-text-gray;
    cursor: pointer;
  }

  .no-points {
    font: $font-400-12;
    color: #71717a;
    line-height: 20px;
    display: block;
  }
}

.layers--active {
  .layers__button {
    max-height: calc(100vh - 40px);
  }

  .layer__button-icon {
    .icon {
      opacity: 0;
    }

    .icon--active {
      opacity: 1;
    }
  }

  .layers__button-small-container {
    border-bottom: 1px solid $color-silver;
  }

  .layers-wrapper {
    max-height: calc(100vh - 40px - 36px);
    padding: 0 0 8px 0;
    border-radius: 8px;
  }

  .layers-wrapper--scroll {
    overflow: auto;
  }
}
