@import 'src/variables';

.secondary {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: $color-text;
    background: $color-white;
    border: 1px $color-text solid;
    border-radius: 4px;
    transition: $transition-duration;
    font: $font-500-14;
    cursor: pointer;

    &:hover {
        background-color: $color-bg-gray;
    }
}