@import "src/variables";

.modal-container {
  max-width: 548px;
}

.delete-modal {
  display: flex;
  flex-direction: column;
  row-gap: 36px;

  .delete-modal__text-container {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .delete-modal__title {
    font: $font-500-16;
  }

  .delete-modal__subtitle {
    font: $font-400-14;
    color: $color-text-gray-light;
  }

  .delete-modal__controls-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 16px;
  }
}
