@import "src/variables.scss";


.team {
    border: 1px #E4E4E7 solid;
    background-color: $color-white;
    border-radius: 8px;
}

.team__header {
    padding: 14px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header__button {
        img {
            transition: $transition-duration;
            transform: rotate(0deg);
        }
    }

}

.team__header--active {
    .header__button {
        img {
            transition: $transition-duration;
            transform: rotate(180deg);
        }
    }

}

.team__body {
    border: 8px;

    .body__top {
        padding: 10px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .staff {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }

    .actions {
        display: flex;
        gap: 16px;
    }
}

.edit-actions {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .edit-actions__title {
        font: $font-500-16;
        line-height: 20px;
        color: #18181B;
    }
}

.staff {
    font: $font-500-12;
    color: #71717A;

    label {
        color: #18181B;
    }
}

.users-table {
    width: 100%;

    .default-item {
        width: auto;
        text-align: left;
        padding: 10px;
        font: $font-400-12;

        &:first-of-type {
            width: 56%;
            padding-left: 24px;
        }

        &:last-of-type {
            width: 10%;
            padding-right: 24px;
        }
    }


    .th-styles {
        @extend .default-item;
        color: #71717A;
    }

    .td-styles {
        @extend .default-item;
        border-top: 1px #E4E4E7 solid;
    }

}

