@import "src/variables";

.modal-container {
  max-width: 980px;
}

.modal {
  display: flex;
  flex-direction: column;
  row-gap: 36px;

  .modal__text-container {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .modal__title {
    font: $font-600-18;
    line-height: 18px;
  }


  .modal__description {
    font: $font-400-14;
    color: $color-text-gray-light
  }

  .modal__controls-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 16px;
  }

  .modal__providers-table {
    margin-top: 36px;

    .providers-table__row {
      display: grid;
      grid-template-columns: minmax(195px, 195px) minmax(272px, 272px) minmax(72px, 72px) minmax(160px, 160px) minmax(160px, 160px);
      gap: 10px;
    }

    .providers-table__head {
      @extend .providers-table__row;
      padding-left: 20px;

      * {
        color: #71717A;
        font: $font-500-12;
        line-height: 20px;
      }
    }

    .providers-table__body {
      @extend .providers-table__row;

      margin-top: 5px;
      padding: 5px 0 5px 20px;
      position: relative;
      background-color: $color-white;

      .drag-dots {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0px, -50%);
      }

      // * {
      //   color: #71717A;
      //   font: $font-500-12;
      //   line-height: 20px;
      // }
    }

    .name {
      color: #71717A;
      font: $font-500-12;
      display: flex;
      align-items: center;
    }

    .link {
      width: 100%;
      height: 36px;
      padding: 8px 12px;
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px #E4E4E7 solid;
      background-color: #F4F4F5;
      font: $font-400-14;
      color: #464649;
      border-radius: 6px;
    }
  }

  .form {
    margin-top: 10px;
    padding-left: 20px;
    display: flex;
    gap: 10px;

    .field {
      width: 100%;
      max-width: 476px;
    }
  }

}