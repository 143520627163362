@import 'src/variables';

.table-container {
    overflow-y: auto;

    table {
        width: 100%;
        border-collapse: collapse;
    }

    thead {
        position: sticky;
        top: 0;
        background-color: $color-white;
    }
}