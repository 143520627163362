@import "src/variables";

.checkbox-component__container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  & label:first-of-type {
    font-weight: 700;
  }

  .checkbox {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    .checkbox__input {
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 0;
      visibility: hidden;
      tab-index: -1;
    }

    .checkbox__indicator {
      position: relative;
      min-width: 16px;
      min-height: 16px;
      border: 1px solid #09090B80;
      background: $color-bg-white;
      border-radius: 4px;

      &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        width: 10px;
        height: 10px;
        opacity: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.125 7.9375L8.15625 17.3125L19.875 0.90625' stroke='black' stroke-width='2'/%3E%3C/svg%3E");
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .checkbox__info {
      .checkbox__info-title {
        font-size: 16px;
        line-height: 23px;
        color: $color-black;
      }

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 100%;
        height: 30px;
        background: green;
      }
    }

    .checkbox-warning {
      color: red;
    }

    &_active {
      .checkbox__indicator {
        &:after {
          opacity: 1;
        }
      }
    }

    &_disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}

@media (max-width: $media-max-width-md) {
  .checkbox-component__container {
    .checkbox {
      .checkbox__info {
        .checkbox__info-title {
          font-size: 16px;
        }
      }
    }
  }
}