@import "variables.scss";

.layer-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.layer__buttons-container {
    margin-bottom: 4px;
}

.multispectral__options {
    width: 100%;
    display: flex;
    gap: 10px;
    margin-bottom: 6px;

    .multispectral__option {
        padding: 4px 12px;
        color: $color-text-gray-light;
        text-align: center;
        background-color: #f4f4f5;
        font: $font-500-14;
        border-radius: 4px;
        line-height: 20px;
        transition: $transition-duration;
        border: none;
        cursor: pointer;

        &:disabled {
            cursor: default;
        }
    }

    .multispectral__option--active {
        color: $color-text;
        background-color: $color-white;
        box-shadow: inset 0 0 0 1px #f4f4f5;
    }
}

.layer__range-container {
    // margin: 10px 0;
    padding: 0 5px;
}