@import 'src/variables';

.dropdown {
    min-width: 240px;
    left: 0;
    bottom: 0;
    padding: 24px 0 0;
    display: flex;
    flex-direction: column;
    position: absolute;
    transform: translateY(100%);
    transition: $transition-duration;
    background-color: $color-white;
    border-radius: 8px;
    overflow: hidden;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    box-shadow: 0px 4px 6px -1px #0000001a;

    .divider {
        width: 100%;
        height: 1px;
        background-color: #e4e4e7;
    }

    .head {
        padding: 0 24px 24px;
    }


    .option {
        padding: 14px 24px;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: left;
        font: $font-500-16;
        color: $color-text;

        &:hover {
            background-color: #f4f4f5;
        }

    }

    .option__icon {
        width: 43px;
        height: 33px;

        img {
            max-width: 100%;
            max-height: 100%;
            height: 100%;
            margin: 0 auto;
        }
    }

}

.dropdown--active {
    bottom: -6px;
    visibility: visible;
    opacity: 1;
}