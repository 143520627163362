@import "src/variables";

.range {
  width: 100%;

  .range__slider {
    :global {
      .rc-slider-handle {
        border-color: #09090b80;
        border-width: 1px;
        height: 16px;
        width: 16px;
        background-color: white;
        opacity: 1;
      }

      .rc-slider-handle:active {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        border-color: none;
        background-color: white;
      }

      .rc-slider-handle-dragging {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      }

      .rc-slider-track {
        background-color: #07905a;
        height: 4px;
      }

      .rc-slider-rail {
        background-color: #18181b1a;
        height: 4px;
      }
    }
  }
}

.range__disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}
