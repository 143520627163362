@import "src/variables";

.container {
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: $color-white;

  &.container-child-preloader {
    position: fixed;
    background: $color-white;
    left: 0;
    top: 0;
    z-index: 15;
    width: 100%;
    height: 100%;
  }

  &.absolute {
    position: absolute;
  }
}

.container:has(.loader__title) {
  padding: 0 24px;
}

.loader__title {
  margin-right: auto;
  margin-bottom: 20px;
  color: #71717A;
  font-size: 14px;
}

.loader {
  width: 100%;
  height: 4px;
  background-color: #ECECEC;
  overflow: hidden;
  position: relative;
  border-radius: 10px;

  &::before,
  &::after {
    content: '';
    left: -20%;
    display: block;
    width: 20%;
    height: 100%;
    background-color: #18181B;
    position: absolute;
    border-radius: 10px;
  }

  &::before {
    animation: move 2s ease-in-out 1s infinite;
  }

  &::after {
    animation: move2 2s ease-in-out infinite;

  }


}

@keyframes move {
  0% {
    left: -20%;
  }

  100% {
    left: 100%;
  }
}

@keyframes move2 {
  0% {
    left: -20%;
  }

  100% {
    left: 100%;
  }
}