@import "variables.scss";

.map-toolbar {
  position: absolute;
  bottom: var(--response-padding);
  left: 50%;
  transform: translate(-50%);
  z-index: 402;
  background-color: $color-bg-white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px;
  cursor: default;

  .icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .icon-active-button {
    background-color: $color-black-bg-primary;
    svg {
      path {
        stroke: white !important;
      }
    }
  }

  .separator {
    width: 1px;
    height: 36px;
    background-color: #ececec;
  }
}
