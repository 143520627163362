@import 'src/variables';

.pagination {
    margin-left: auto;
    display: flex;
    gap: 32px;
    align-items: center;

    .pagination__btns {
        display: flex;
        gap: 8px;
    }

    .pagination__stats {
        color: $color-text;
        font: $font-500-14;
    }
}