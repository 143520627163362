@import 'src/variables';

.log-out {
    display: flex;
    padding: 24px;
    cursor: pointer;
    gap: 10px;
    align-items: center;
    font: $font-500-16;
    color: $color-text;

    &:hover {
        background-color: #f4f4f5;
    }
}