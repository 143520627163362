@import "src/variables";

.main-page {
    display: flex;
    gap: 60px;
}

@media (max-width: $media-max-width-xl) {
    .main-page {
        gap: 20px;
    }
}

@media (max-width: $media-max-width-transitional-xl) {
    .main-page {
        flex-direction: column;
    }
}

@media (max-width: $media-max-width-lg) {
    .main-page {
        flex-direction: column;
    }
}