@import 'src/variables';

.primary {
    padding: 8px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: $color-white;
    background: $color-black-bg-primary;
    border-radius: 4px;
    line-height: 20px;
    font: $font-500-12;
    border: none;
    cursor: pointer;

    &:hover {
        background-color: #18181be6;
    }

    .primary__icon {
        width: 16px;
        height: 16px;

        img {
            width: 100%;
            height: auto;
        }
    }
}

.primary--disable {
    cursor: default;
    opacity: .5;

    &:hover {
        background: $color-black-bg-primary;
    }
}

.primary--large {
    padding: 10px 32px;
}