@import "variables.scss";

.search {
  .icon-button {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .icon-active-button {
    background-color: $color-black-bg-primary;
    svg {
      path {
        stroke: white;
      }
    }
  }

  .form {
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translate(-265px, 0);
    display: flex;
    align-items: center;
    gap: 10px;
    width: 0px;
    overflow: hidden;
    opacity: 0;
    transition: width 0.3s ease, opacity 0.3s ease;

    & > * {
      opacity: 0;
      transition: opacity 0.4s ease;
    }
  }

  .form__active {
    width: 530px;
    overflow: visible;
    opacity: 1;
    transition: width 0.3s ease, opacity 0.3s ease;

    & > * {
      opacity: 1;
      transition: opacity 0.4s ease;
    }
  }

  .search-field {
    input {
      font: $font-400-14;
      line-height: 20px;
      padding: 7px 12px;
    }
  }

  .search-button {
    font: $font-500-14;
    line-height: 20px;
  }
}

.marker {
  cursor: default;
}

.marker__actions {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);

  .marker__button {
    padding: 1px 4px;
    gap: 4px;
    font-size: 10px;
  }
}
