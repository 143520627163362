@import "src/variables";

.image-detection-page {
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
    position: relative;

    .image-detection-page__type {
        top: 20px;
        left: 364px;
        display: flex;
        position: absolute;
        gap: 10px;

        .layer {
            min-width: 148px;
            border-radius: 8px;
            justify-content: start;
            opacity: 0.5;

            &--active {
                @extend .layer;
                opacity: 1;
            }
        }
    }

    .image-detection-page__form {
        top: 108px;
        left: var(--response-padding);
        position: absolute;
        display: flex;
        gap: 10px;
        align-items: start;
    }

    canvas {
        flex-grow: 1;
        height: 100%;
        width: 100%;
        max-width: 100%;
    }
}