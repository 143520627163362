@import "src/variables";

.wrapper {
  width: 100%;
  max-width: 500px;

  .wrapper__head {
    min-height: 40px;
    margin-bottom: 40px;
    display: flex;
    gap: 20px;
    align-items: center;

    .title {
      margin-right: auto;
      font: $font-700-24;
    }
  }

  .wrapper__body {
    display: flex;
    flex-direction: column;
    gap: 36px;
  }

  .utils-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    max-height: 560px;
  }
}

@media (max-width: $media-max-width-lg) {
  .wrapper {
    .utils-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
    }
  }
}

@media (max-width: $media-max-width-transitional-xl) {
  .wrapper {
    max-width: 100%;
    
    .utils-list {
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        max-height: 100%;
    }
  }
}

@media (max-width: $media-max-width-sm) {
  .wrapper {
    .utils-list {
      grid-template-columns: repeat(1, 1fr);
      max-height: 100%;
    }
  }
}
