@import "src/variables";

.wrapper {
  width: 100%;

  .wrapper__head {
    min-height: 40px;
    margin-bottom: 40px;
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;

    .title {
      margin-right: auto;
      font: $font-700-24;
    }
  }

  .wrapper__body {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .wrapper__projects {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: $color-white;
    overflow-x: auto;
  }

  .projects-table__header {
    display: grid;
    grid-template-columns: minmax(300px, 10fr) minmax(150px, 2fr) minmax(
        80px,
        80px
      );
    padding: 10px 19px 10px 24px;
    background-color: $color-white;
    border-radius: 8px;
    gap: 20px;
  }

  .projects-table__header-label {
    font: $font-500-12;
    line-height: 20px;
    color: $color-text-gray-light;
  }
}

@media (max-width: $media-max-width-lg) {
  .wrapper {
    width: 100%;

    .wrapper__head {
      .title {
        width: 100%;
      }
    }

    .wrapper__projects {
      max-height: 100%;
    }
  }
}
